<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      item-value="name"
      max-height="800px"
      :items-per-page="6500"
    >
      <template #item.author="{ item }">
        {{ item.author.replace(/\s\S+@\S+.\S+/g, '') }}
      </template>
      <template #item.actions="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="currentLib = item">
              <v-icon>mdi mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>More Info</span>
        </v-tooltip>
        <project-add-lib :lib="item" />
      </template>
    </v-data-table>

    <v-dialog :value="!!currentLib" max-width="500" @input="currentLib = null">
      <v-card v-if="currentLib">
        <v-card-title>
          {{ currentLib.name }}
          <v-spacer />
          <v-chip color="info">
            {{ currentLib.version }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <pre>{{ getDesc(currentLib) }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :href="currentLib.website" target="_blank" color="info" text>
            <v-icon left>mdi-open-in-new</v-icon>
            Website
          </v-btn>
          <v-btn text @click="currentLib = null">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import debouncePromise from 'debounce-promise';
import projectAddLib from './project-add-lib.vue';

export default {
  components: {
    projectAddLib,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentLib: null,
      total: 0,
      items: [],
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Version', sortable: false, value: 'version' },
        { text: 'Author', sortable: false, value: 'author' },
        { text: 'Category', sortable: false, value: 'category' },
        { text: 'Actions', sortable: false, value: 'actions' },
      ],
    };
  },
  watch: {
    search() {
      this.loadLibs();
    },
  },
  async mounted() {
    await this.loadLibs();
  },
  methods: {
    async loadLibs() {
      this.loading = true;
      try {
        const searchQuery = process.env.VUE_APP_ARDUINO_REQUIRED_LIB_LIST;
        const res = await this.$compiler.librariesSearch(searchQuery, 6500, {
          sortBy: "name",
          sortDesc: false,
          exact: true,  // Ensures an exact match for the library names
        });
        this.items = res.data;
        this.total = res.total;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
    getDesc(lib) {
      if (lib.paragraph?.indexOf(lib.sentence) === 0) {
        return lib.paragraph.replace(/<br ?\/?>/g, '\n');
      }
      return `${lib.sentence}\n\n${lib.paragraph ?? ''}`.replace(/<br ?\/?>/g, '\n');
    },
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-line;
  font-family: inherit;
}
</style>